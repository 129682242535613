<template>
  <div class="faq-conten-con">
    <!-- <h2 class="h2-faq">자주 묻는 질문 TOP 20</h2> -->
    <div class="search-box faq-search-box">
      <input v-model="searchText" type="text" class="text" />
      <button class="btn __search" @click="search">검색</button>
    </div>
    <ul class="qna-list" id="qnaList">
      <li v-for="(faq, idx) in faqs" :key="`faq_${idx}`">
        <div
          class="q"
          :class="{ on: activeNum === faq.faqId }"
          @click="openAnswer(faq.faqId)"
        >
          {{ ` ${idx + 1}. ${faq.title} ` }}
        </div>
        <div
          v-html="faq.content"
          class="a"
          :class="{ on: activeNum === faq.faqId }"
        ></div>
      </li>
    </ul>
    <!-- <Pagination
      :totalElements="totalElements"
      :currentPageIndex="currentPageIndex"
      :listRowCount="listRowCount"
      :pageLinkCount="pageLinkCount"
      @goPage="onGoPage"
    >
    </Pagination> -->
  </div>
</template>

<script>
import { getFaqList, getFaqDetail } from 'Api/modules'
// import Pagination from 'Common/Pagination'
export default {
  // components: {
  //   Pagination,
  // },
  data() {
    return {
      activeNum: null,
      searchText: '',
      faqs: [],

      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
    }
  },
  methods: {
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    async search() {
      const res = await getFaqList({
        schKeyword: this.searchText,
        pageNumber: this.pageNumber,
      })
      this.faqs = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
    },
    openAnswer(faqId) {
      if (this.activeNum === faqId) this.activeNum = null
      else {
        this.activeNum = faqId
        getFaqDetail
      }
    },
  },
  created() {
    this.search()
    //console.log(this.faqs)
  },
}
</script>

<style></style>
